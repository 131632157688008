.news-pagination-holder {
    text-align: center;

    .news-pagination {
        display: inline-block;
    }

    a {
        font-size: em(24px);
        font-weight: bold;
        padding: 10px;
        float: left;
    }

    a span {
        display: block;
        transition: transform 0.2s ease;
    }

    a:hover > span {
        transform-origin: '50% 50%';
        transform: scale(1.5);
    }

    a:first-child {
        margin-right: 30px;

        &::after {
            content: '';
            display: block;
            position: absolute;
            width: 30px;
            height: 1px;
            background-color: $fontColor;
            left: 100%;
            top: 50%;
        }
    }
}

section.news {
    .post-date {
        color: #9a968e;
        font-size: em(18);
        font-weight: 700;

        @media only screen and (min-width: 768px) {
            font-size: em(21);
        }
    }
}

#singlenewspage .img-holder img {
    width: 100%;
    object-fit: cover;
    margin-bottom: 25px;
}

#singlenewspage {
    .post-date {
        display: block;
        color: #9a968e;
        font-weight: 700;
        font-size: em(18);
        margin-bottom: 10px;

        @media only screen and (min-width: 768px) {
            font-size: em(21);
        }
    }
}
