/* CLASSES */
.group::after {
    content: '';
    display: table;
    clear: both;
}

.text-indent {
    padding: 0 20px;

    .text-indent {
        padding: 0;
    }
}

.intro {
    font-size: 1em;
    line-height: 1.5;
    font-family: Tiempo, sans-serif;
}

.read-more {
    display: block;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 9px;
        left: 0;
        width: 24px;
        height: 2px;
        background-color: #5ac5b2;
        z-index: 1;
    }

    &.white::before {
        background-color: #fff;
    }

    svg {
        position: relative;
        width: 27px;
        transition: transform 0.3s ease;
    }

    &--popup::before {
        background-color: #feed5f;
    }
}

.full-width {
    width: 100%;
    height: auto;
    display: block;
    margin-bottom: 40px;
}

.full-width-image {
    width: 100%;
    height: auto;
    display: block;
    margin-top: 40px;
}

// TODO: Refactor label logic, this is getting out of hand with page overrules.
.labels {
    content: '';
    display: table;
    clear: both;

    .label {
        font-size: 0.6875em;
        text-transform: uppercase;
        padding: 6px 8px;
        color: #fff;
        float: left;
        margin-right: 5px;
        font-weight: bold;
        letter-spacing: 0.05em;
    }
}

.label.special,
#movieoverviewpage .movies-holder .item .label.special,
#archivepage .movies-holder .item .label.special {
    color: #fff;
    background-color: $color5;
}

.label.film,
#movieoverviewpage .movies-holder .item .label.film,
#archivepage .movies-holder .item .label.film,
.label.movie,
#movieoverviewpage .movies-holder .item .label.movie,
#archivepage .movies-holder .item .label.movie {
    color: #fff;
    background-color: $color2;
}

.label.premiere,
#movieoverviewpage .movies-holder .item .label.premiere,
#archivepage .movies-holder .item .label.premiere {
    color: #fff;
    background-color: $fontColor;
}

.label.laatste-week,
#movieoverviewpage .movies-holder .item .label.laatste-week,
#archivepage .movies-holder .item .label.laatste-week,
.label.last-week,
#movieoverviewpage .movies-holder .item .label.last-week,
#archivepage .movies-holder .item .label.last-week,
.label.laatste-kans,
#movieoverviewpage .movies-holder .item .label.laatste-kans,
#archivepage .movies-holder .item .label.laatste-kans,
.label.last-chance,
#movieoverviewpage .movies-holder .item .label.last-chance,
#archivepage .movies-holder .item .label.last-chance {
    color: #fff;
    background-color: $red;
}

.article-block {
    display: block;
    margin-bottom: 30px;

    img {
        display: block;
        width: 100%;
        height: auto;
    }

    .article-text {
        background-color: #fff;
        padding: 25px 20px;
        box-sizing: border-box;
        height: 185px;
        overflow: hidden;

        .post-date {
            color: #9a968e;
            font-weight: 700;
        }

        h2 {
            font-size: 1.4375em;
            line-height: 1.1;
            margin-bottom: 20px;
        }

        p {
            line-height: 1.5;
            font-size: 0.875em;
            margin-bottom: 0;
        }
    }

    .more-info {
        display: block;
        padding: 15px 20px 0;
        font-size: 1em;
        font-weight: bold;
        color: #fff;
        background-color: $color2;
        height: 50px;
        box-sizing: border-box;
        position: relative;
        transition: background 0.3s ease;

        svg {
            position: absolute;
            right: 37px;
            top: 50%;
            transform: translateY(-50%);
            width: 9px;
            height: 14px;
        }
    }
}

.no-touchevents .article-block .more-info:hover {
    background-color: $color4;
}

.no-touchevents #organizepage .article-block:hover .more-info {
    background-color: $color4;
}

.button,
.text-indent .button {
    background-color: $color2;
    font-weight: bold;
    color: #fff;
    padding: 15px 20px;
    display: inline-block;
    margin-bottom: 20px;
    font-size: 1em;
    text-decoration: none;
    transition: background 0.3s ease;
    line-height: normal;
}

.button--cta {
    text-decoration: none !important;
}

input[type='submit'].button {
    cursor: pointer;
    border: none;
}

.button.black {
    background-color: $fontColor;
}

.no-touchevents .button:hover,
.no-touchevents .button.black:hover {
    background-color: $color4;
}

.no-touchevents .button--cta:hover {
    background-color: $color1;
}

.img-holder {
    position: relative;
    overflow: hidden;

    img {
        transform: scale(1);
        transition: transform 0.3s ease;
    }
}

.no-touchevents a:hover .img-holder img {
    transform: scale(1.1);
    transition: transform 6s ease;
}

.close-btn {
    width: 30px;
    height: 30px;
    background-color: #fff;
    cursor: pointer;

    svg {
        fill: #3b3530;
        width: 10px;
        height: 10px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: transform 0.6s cubic-bezier(0, 0, 0.37, 0.99);
    }

    &:hover {
        svg {
            transform: translate(-50%, -50%) rotate(-180deg);
        }
    }
}

@media only screen and (min-width: 768px) {
    .intro {
        font-size: 1.375em;
    }

    .article-blocks-holder {
        margin-bottom: 20px;
    }

    .article-block {
        margin-bottom: 25px;

        .article-text {
            padding: 25px 30px;
            height: 290px;

            .post-date {
                font-size: em(21);
            }

            h2 {
                font-size: 1.875em;
                margin-bottom: 20px;
            }

            p {
                font-size: 1em;
            }
        }

        .more-info {
            font-size: 1.375em;
            padding-top: 13px;
            padding-left: 30px;

            svg {
                right: 42px;
                width: 12px;
                height: 18px;
            }
        }
    }

    .labels {
        .label {
            font-size: 0.875em;
            padding: 6px 8px;
        }
    }

    .close-btn {
        width: 60px;
        height: 60px;

        svg {
            width: 19px;
            height: 19px;
        }
    }

    p.buttons .button:first-child {
        margin-right: 20px;
    }
}

@media only screen and (min-width: 1024px) {
    .text-indent {
        padding: 0 30px;
    }

    .article-block {
        margin-bottom: 20px;
        width: 300px;
        float: left;
        display: inline-block;

        &:nth-of-type(even) {
            margin-left: 20px;
        }

        .article-text {
            height: 325px;

            h2 {
                font-size: 1.5em;
            }
        }
    }

    .button,
    .text-indent .button {
        padding: 11px 30px 12px;
        margin-bottom: 40px;
        font-size: 1.375em;
    }

    p .button,
    .text-indent p .button {
        font-size: 1.22em;
        line-height: normal;
    }
}

@media only screen and (min-width: 1440px) {
    .text-indent {
        padding: 0 60px;
    }

    .article-block {
        width: 380px;

        .article-text {
            height: 290px;

            h2 {
                font-size: 1.875em;
            }
        }
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
