/* ELEMENTS */
h1,
h2,
h3,
h4,
h5,
h6 {
    padding: 0;
    margin: 0;
    font-weight: normal;
}

h1 {
    font-size: 1.5em;
    margin-bottom: 25px;
    line-height: 1.1;
    font-style: normal;
    font-weight: bold;
}

h2 {
    font-size: 1.4375em;
    line-height: 1.5;
    margin-bottom: 20px;
    font-weight: bold;
}

.text-indent {
    h2 {
        line-height: 1;
        margin-bottom: 30px;
    }
}

h3 {
    font-size: 1.3em;
    margin-bottom: 27px;
    font-weight: bold;
}

p {
    margin: 0 0 18px 0;
    line-height: 1.5;
    margin-bottom: 20px;
}

a {
    cursor: pointer;
    font-weight: normal;
    text-decoration: none;
    position: relative;
    color: $fontColor;
}

.text-indent a {
    color: $color1;
    text-decoration: underline;
}

em {
    font-style: italic;
}

video {
    width: 100%;
    height: auto;
}

table {
    border: none;
}

input:focus {
    outline: none;
}

@media only screen and (min-width: 768px) {
    h1 {
        font-size: 2.4em;
        margin-bottom: 60px;
    }

    h2 {
        font-size: 1.875em;
    }

    p {
        margin-bottom: 20px;
    }
}

@media only screen and (max-height: 1024px) and (min-width: 768px) {
    h1 {
        margin-bottom: 30px;
    }
}

.input-holder {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    label {
        margin-bottom: 12px;
    }

    input[type='text'],
    input[type='date'],
    input[type='number'],
    textarea {
        background-color: white;
        border: none;
        box-shadow: none;
        padding: 15px 20px;
        font-family: CalibreWeb, sans-serif;
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 5px;

        &:focus {
            box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.12);
        }
    }

    textarea {
        resize: none;
    }

    select {
        position: relative;
        background-color: white;
        border: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none; /* Remove default arrow */
        background-image: url('/media/images/arrow-down.svg');
        background-repeat: no-repeat;
        background-position: center right;
        width: 100%;

        &::after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: white;
            pointer-events: none;
        }
    }
}

.input-holder.has-error {
    input[type='text'],
    input[type='date'],
    input[type='number'],
    textarea {
        border: 2px solid red;
    }
}

.select-holder {
    padding: 15px 20px;
    background-color: white;
}

.error-message {
    color: red;
}

.checkbox--styled {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it

    & + label {
        position: relative;
        cursor: pointer;
        padding: 0;
    }

    // Box.
    & + label::before {
        content: '';
        margin-right: 10px;
        display: inline-block;
        vertical-align: text-top;
        width: 20px;
        height: 20px;
        background: white;
    }

    // Box hover
    &:hover + label::before {
        background: $green;
    }

    // Box focus
    &:focus + label::before {
        box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.12);
    }

    // Box checked
    &:checked + label::before {
        background: $green;
    }

    // Disabled state label.
    &:disabled + label {
        color: #b8b8b8;
        cursor: auto;
    }

    // Disabled box.
    &:disabled + label::before {
        box-shadow: none;
        background: #ddd;
    }

    // Checkmark. Could be replaced with an image
    &:checked + label::after {
        content: '';
        position: absolute;
        background-image: url('/media/images/checkbox.svg');
        top: 4px;
        left: 5px;
        background-repeat: no-repeat;
        background-size: cover;
        width: 10px;
        height: 9px;
    }
}

.three-sixty {
    margin-bottom: 30px;
}
